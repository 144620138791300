<template>
  <div class="container" id="queryContainer">
    <div class="page-sub-box locker_setting_list sub_new_style01 sub_ui_box1 pad_top20">
      <div class="cusmain-table-wrap">
        <esp-dx-data-grid ref="masterQueryGrid" :data-grid="dataGrid" />
      </div>
    </div>
    <DxPopup :isOpen="modal.isOpened" :option="modal.option" @saveModal="asyncSaveXmlModal" @closeModal="toggleXmlModal(false)">
      <template #content>
        <XmlEditor ref="xmlEditor" :options="modal.editorOption" :history="modal.history" />
      </template>
    </DxPopup>
  </div>
</template>

<script>
  import DxPopup from '@/components/devextreme/esp-dx-modal-popup.vue';
  import XmlEditor from '@/pages/report/config/modal-xml-editor-update';
  import { isSuccess } from '@/plugins/common-lib';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = null;
  export default {
    components: {
      EspDxDataGrid,
      DxPopup,
      XmlEditor,
    },
    props: {},
    watch: {},
    data() {
      return {
        modal: {
          isOpened: false,
          selectedName: null, // 'name' attribute of sql tag
          history: null, // sql 히스토리
          option: {
            title: 'XML 보고서 등록',
            width: '80%',
            height: '90%',
            minWidth: null,
            minHeight: null,
          },
          editorOption: {
            type: 'MASTER',
            masterNm: '', // name
            reportNm: '', // ctiType
            reportId: undefined, // historyId
            rsWidth: '30%',
            useCreateCols: true,
          },
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          //selectedValue: null,
          //searchText: null,
          paramsData: null,
        },
        dataGrid: {
          refName: 'masterQueryGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          // height:'500',    // 주석처리시 100%
          apiActionNm: {
            select: 'MASTER_QUERY_LIST',
          },
          showActionButtons: {
            select: false,
            copy: false,
            delete: false,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '등록',
                  elementAttr: { class: 'btn_XS default filled' },
                  width: 60,
                  height: 30,
                  onClick() {
                    vm.toggleXmlModal(true);
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick() {
                    vm.asyncDeleteSqlInMasterQuery();
                  },
                },
                location: 'before',
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          // scrolling: { // 미사용시 주석처리
          //     mode: 'virtual' //스크롤 모드 : ['infinite', 'standard', 'virtual']
          // },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },

          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowDeleting: false,
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: 'title',
              dataField: 'title',
              height: 40,
              alignment: 'left', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              cellTemplate: (container, item) => {
                let aTag = document.createElement('a');
                aTag.innerText = item.value;
                aTag.addEventListener('click', () => {
                  vm.toggleXmlModal(true, item.data);
                });
                container.append(aTag);
              },
            },
            {
              caption: 'name',
              dataField: 'name',
              height: 40,
              alignment: 'left',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporing: true,
            },
            {
              caption: 'targetTable',
              dataField: 'targetTable',
              height: 40,
              alignment: 'left',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporing: true,
            },
            {
              caption: 'tabletype',
              dataField: 'tabletype',
              height: 40,
              alignment: 'left',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporing: true,
            },
            {
              caption: 'ctitype',
              dataField: 'ctitype',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowExporing: true,
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /** @description: 모달 열기/닫기 */
      toggleXmlModal(isOpen = true, targetData = null) {
        this.setModalOption(targetData);
        this.modal.isOpened = isOpen;
      },
      setEditorOption(key, value) {
        this.modal.editorOption[key] = value;
      },
      async setModalOption(targetData) {
        // 1. title 클릭 시에는 targetData 셋팅 O -> 수정
        // 2. 등록 버튼 클릭 시에는 targetData 셋팅 X -> 등록
        this.modal.option.title = `XML 보고서 ${targetData ? '수정' : '등록'}`;
        if (targetData) {
          const { title, ctitype, name } = targetData;
          this.modal.selectedName = name;
          this.setEditorOption('reportId', ctitype);
          this.setEditorOption('reportNm', title);
          this.setEditorOption('masterNm', name);
          this.setEditorOption('useRight', true); // 히스토리 및 설명
          const sql = await this.asyncGetSqlByNameAndCtiType(name, ctitype);
          await this.asyncMasterQueryHistoryByName(name);
          this.$refs.xmlEditor.setCmEditor(sql);
        } else if (targetData === null) {
          this.setEditorOption('reportId', undefined);
          this.setEditorOption('reportNm', '');
          this.setEditorOption('useRight', false); // 히스토리 및 설명
          this.$refs.xmlEditor.clearVueData();
        }
      },
      async asyncGetSqlByNameAndCtiType(name, ctiType) {
        const params = { name, ctiType };
        const payload = {
          actionname: 'MASTER_QUERY_SQL_SELECT',
          data: { params: params },
          loading: true,
        };

        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          return res.data.data.sql;
        }
        return '';
      },
      async asyncMasterQueryHistoryByName(name) {
        const params = { sort: '-editDt', name };
        const payload = {
          actionname: 'MASTER_QUERY_SQL_HISTORY',
          data: { params: params },
          loading: true,
        };

        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          this.modal.history = res.data.data;
        }
      },
      async asyncSaveXmlModal() {
        // 초깃값 '등록'
        let confirmMsg = '신규 마스터 쿼리가 추가되었습니다.';
        let actionname = 'MASTER_QUERY_SQL_INSERT';

        // Param
        const { cmEditor: sqlXml } = this.$refs.xmlEditor;
        const params = { sqlXml, useSqlPass: false };
        const sqlName = this.modal.editorOption.reportNm;
        if (sqlName !== '') {
          // Update 시
          // parameter
          params.name = this.modal.selectedName;
          params.description = this.$refs.xmlEditor.description;
          // actionName & responseMessage
          confirmMsg = `'${sqlName}'가 변경되었습니다.`;
          actionname = 'MASTER_QUERY_SQL_UPDATE';
        }

        const res = await this.CALL_API({
          actionname,
          data: params,
          loading: true,
          useErrorPopup: false,
        });

        const { resCode, resMsg } = res.data.header;
        if (isSuccess(res) && (await this.$_Msg(confirmMsg))) {
          this.proccessSuccessSave();
        } else if (resCode !== 'R002') {
          this.$_Msg(resMsg);
        } else if (resCode === 'R002' && (await this.$_Confirm(`${resMsg} 강제로 저장시키겠습니까?`))) {
          const forceRes = await this.CALL_API({
            actionname: actionname,
            data: { ...params, useSqlPass: true },
            loading: true,
          });

          if (isSuccess(forceRes) && (await this.$_Msg(confirmMsg))) {
            this.proccessSuccessSave();
          }
        }
      },
      proccessSuccessSave() {
        this.toggleXmlModal(false);
        this.$refs.masterQueryGrid.refreshData();
        this.$refs.xmlEditor.clearVueData();
      },
      async asyncDeleteSqlInMasterQuery() {
        const instance = this.$refs.masterQueryGrid.getGridInstance;
        const names = instance.getSelectedRowKeys();
        if (names.length === 0) {
          await this.$_Msg('삭제할 쿼리를 선택해주시기 바랍니다.');
          return;
        }

        if (await this.$_Confirm('해당 쿼리를 삭제하시겠습니까?')) {
          const params = { data: names };
          const actionname = 'MASTER_QUERY_SQL_DELETE';
          const res = await this.CALL_API({
            actionname,
            data: params,
            loading: true,
          });

          if (isSuccess(res) && (await this.$_Msg('삭제가 완료되었습니다.'))) {
            this.$refs.masterQueryGrid.refreshData();
          }
        }
      },
      setVm() {
        vm = this;
      },
    },
    created() {
      this.setVm();
    },
    mounted() {},
  };
</script>

<style scoped>
  #searchDept .title {
    display: inline-block;
    margin-right: 10px;
  }
  #searchDept .dx-button.dx-button-has-icon:not(.dx-button-has-text):not(.dx-shape-standard) {
    border-radius: 4px;
  }
  #queryContainer .empty-line {
    display: block;
    margin-right: 0;
    margin-bottom: 5px;
  }
  .sub_new_style01 .page_search_box .inner div {
    display: inline-block;
  }
  .sub_new_style01 .page_search_box .inner > div {
    vertical-align: middle;
    margin-right: 10px;
  }
</style>
